body,
html {
  height: 99%;
}

body {
  font-size: 14px;
  color: #434a51;
  background-color: #F5F6FA;
  min-height: 100%;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Lato";
  src: url(".//Lato-Regular.woff") format("woff");
  font-weight: 100;
}

.LatoFont {
  font-family: Lato !important;
}

.j-content-center {
  justify-content: center;
}

.text-right {
  text-align: right;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.mt-15 {
  margin-top: 15px;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.w-100 {
  width: 100%;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.prm-container {
  /* padding: 0 15px; */
  min-height: calc(98vh - 50px);
  height: 100%;

}

.MuiBox-root {
  gap: 0 !important;
  padding: 0 !important;
  justify-content: flex-start !important;
}

/*** Header and Footer Start ***/

.top-header {
  color: #142933;
  font-size: 16px;
  font-family: "Lato";
  font-weight: 700;
  letter-spacing: 1.3px;
  background-color: #fff;
  padding: 10px 25px;
}

.logo-layout {
  background-image: url("images/logo-IntegrityConnect.svg");
  background-repeat: no-repeat;
  height: 51px;
}

.footer-wrap {
  font-family: "Lato" !important;
  text-align: center;
  font-size: 16px;
  font-style: italic;
  color: #434a51;
  font-weight: 400;
  padding: 20px 0 20px 3px;

}

.app-title {
  font-family: Lato !important;
  font-size: 32px;
  font-weight: 400;
  color: #051D43;
  padding: 30px 25px 5px;
}

/*** Header End ***/

/***Main Wrapper Start***/
/***Search Grid Start**/
.add-search-container {
  position: relative;
  z-index: 99;
  margin-top: 35px;
  background-color: #fff;
  border-radius: 4px;
  margin-right: 25px;
  margin-left: 25px;
}

.search-wrap {
  width: 495px;
  border: 1px solid #dfdedd;
  border-radius: 4px;
  margin-left: 20px;
  background-color: #fff;
  display: inline-block;
}

.search-wrap .MuiInputBase-roo.MuiInput-root,
.search-wrap .MuiInputBase-root.MuiOutlinedInput-root {
  font-family: Lato !important;
}

.search-wrap .text-field {
  display: inline-flex;
  position: relative;
}

.search-wrap .i-search-icon:before {
  content: url("fonts/search.svg") !important;
  position: absolute;
  top: 10px;
  right: 14px;
  cursor: pointer;
}

.search-wrap .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
  border-width: 0 !important;
}

.search-wrap .MuiInputBase-root.MuiOutlinedInput-root.MuiSelect-root {
  width: 130px;
}

.search-wrap .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input {
  width: 125px;
  padding: 7px 14px;
}

.search-wrap .MuiFormControl-root.MuiTextField-root {
  width: 340px;
}

.search-wrap .MuiInputBase-input.MuiInput-input {
  padding: 7px 0 7px;
}

.search-wrap .MuiInputBase-root.MuiInput-root:before,
.search-wrap .MuiInputBase-root.MuiInput-root:after {
  border-bottom: 0 solid transparent !important;
}

.search-wrap .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.save-btn-wrap {
  display: inline-block;
  margin-left: 15px;
}

.save-btn-wrap .MuiButtonBase-root.MuiButton-root {
  background-color: #0052ce;
  border: 1px solid #0052ce;
  color: #fff !important;
  box-shadow: none;
}

/***Search Grid end**/

.MuiTableContainer-root {
  min-height: auto !important;
}

.MuiTableContainer-root .MuiTable-root {
  width: 99.8%;
}

.MuiTableContainer-root .MuiTableRow-root {
  box-shadow: none !important;
}

.MuiPaper-elevation {
  margin-top: -70px;
  padding: 15px 25px;
  border: none !important;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  background: #fff !important;
  border-radius: 8px !important;
  margin-right: 20px;
  margin-left: 20px;
  /* border: 1px solid #dfdedd !important; */
  /* box-shadow: 0 2px 5px 0 rgba(0,0,0,.16) !important; */

}

.MuiTypography-root {
  max-height: 400px;
  overflow: auto;
}


/***Main Wrapper End***/

/**Add/Edit Job Start**/
body .add-job-btn {
  font-family: "Lato" !important;
  font-size: 16px !important;
  margin-top: 8px;
}

.add-btn-wrap {
  display: inline;
  margin-top: 12px;
  text-align: right;
}

.add-job-btn {
  background-color: #0052ce !important;
  border: 1px solid #0052ce !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  min-height: 40px !important;
  box-shadow: none !important;
  border-radius: 3px !important;
  margin-right: 25px !important;
}

.add-job-btn {
  /* position: absolute !important;
  right: 45px;
  z-index: 2;
  top: 126px; */
  text-transform: capitalize !important;
  background-color: transparent !important;
  border: 0 solid #33478a !important;
  color: #0052ce !important;
  padding: 0 !important;
  min-height: 10px !important;
  font-weight: 700 !important;
}

.add-job-btn:before {
  content: url("fonts/plus.svg") !important;
  margin-right: 5px;
}

.add-job-btn .MuiTouchRipple-root {
  display: none;
}

/**Add/Edit Job End**/


/*** Table Grid Start ***/
.MuiBox-root.css-1tbggly {
  display: none;
}

.MuiTableContainer-root {
  margin-top: 70px;
  margin-bottom: 10px;
}

.MuiTable-root {
  border: .5px solid #dfdedd;
}


.MuiTableCell-head {
  background-color: #F5F6FA !important;
  padding: 12px 13px 12px 13px !important;
}

th.MuiTableCell-head {
  border-right: 0.5px solid #dfdedd;
  font-family: Lato !important;
}

tr.MuiTableRow-root th:last-child,
tr.MuiTableRow-root td:last-child {
  border-right: none !important;
}

tr.MuiTableRow-root th:last-child .Mui-TableHeadCell-Content {
  margin-left: 10px;
}

tr.MuiTableRow-root td:last-child .MuiButtonBase-root.MuiIconButton-root {
  margin-left: 20px;
}

.MuiTableCell-root.MuiTableCell-body {
  font-family: Lato !important;
}

.MuiTableCell-body .MuiButtonBase-root.MuiIconButton-root {
  border-radius: 4px !important;
  opacity: 1 !important;
}

.MuiTableCell-body .MuiButtonBase-root.MuiIconButton-root:hover {
  background-color: #f4f6fa !important;
}

tbody.MuiTableBody-root tr:last-child td {
  border-bottom: none !important;
}

body .MuiTableCell-root td,
body thead tr th div {
  font-family: "Lato" !important;
  font-size: 16px !important;
}


.Mui-TableHeadCell-Content-Labels {
  font-size: 16px;
  font-weight: 700;
  color: #000;
}

.css-lapokc {
  min-width: 4ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000000;
  font-weight: 700;
  font-size: 16px;
}

.MuiTableCell-body {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #434a51 !important;
  border-right: 0.5px solid #dfdedd;
  word-break: break-word;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiTableSortLabel-icon.MuiTableSortLabel-iconDirectionAsc,
.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  color: #0052ce !important;
}

.MuiTableRow-root:has(.paused-tr) {
  background-color: #ffebcd !important;
  /* background-color: #f7c0c7 !important; */
  /* background-color: #ffc1bb !important; */
  /* background-color: #ffe1b1 !important; */
}

.MuiTableRow-root:hover td {
  background-color: transparent !important;
}

/*** Table Grid End ***/

/**Table Dropdown Start***/

.MuiPaper-elevation.MuiMenu-paper {
  padding: 5px 5px 0 5px;
  border-radius: 3px !important;
  margin-top: 1px;
  margin-left: 2px;
  box-shadow: rgb(185, 183, 183) -1px 3px 12px -1px !important;
}

.multi-menu {
  margin-bottom: 5px !important;
}

.multi-menu:hover {
  background-color: #ededee !important;
  color: #0052ce !important;
  border-radius: 3px;
}

.multi-menu.run-icon::before {
  content: url("fonts/run.svg") !important;
  margin-right: 15px;
}

.multi-menu.edit-icon::before {
  content: url("fonts/edit.svg") !important;
  margin-right: 12px;
}

.multi-menu.copy-icon::before {
  content: url("fonts/copy.svg") !important;
  margin-right: 12px;
}

.multi-menu.resume-icon::before {
  content: url("fonts/resume.svg") !important;
  margin-right: 12px;
}

.multi-menu.pause-icon::before {
  content: url("fonts/pause-circle.svg") !important;
  margin-right: 12px;
}

.multi-menu.delete-icon::before {
  content: url("fonts/trash.svg") !important;
  margin-right: 15px;
}


/**Table Dropdown End***/

/*** Table Pagination Start ***/
.MuiPagination-root {
  margin-top: 10px;
  margin-right: 195px;
}

.MuiPagination-root ul.MuiPagination-ul {
  margin-top: -3px;
}

.MuiPagination-ul .MuiButtonBase-root.MuiPaginationItem-root:hover {
  background-color: transparent;
}

.MuiPagination-ul .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected {
  background-color: #cde2f8 !important;
  border-radius: 0;
  border: 0.5px solid #dfdedd;
}

.MuiPagination-root ul.MuiPagination-ul li {
  margin-right: -7px;
}

.MuiPagination-root ul.MuiPagination-ul li:first-child {
  margin-right: 8px;
}

.MuiPagination-root ul.MuiPagination-ul li:last-child {
  margin-left: 12px;
}

.MuiPagination-root ul.MuiPagination-ul li:first-child {
  margin-right: 5px;
}

.MuiPagination-root ul.MuiPagination-ul li:first-child .MuiButtonBase-root.MuiPaginationItem-root,
.MuiPagination-root ul.MuiPagination-ul li:last-child .MuiButtonBase-root.MuiPaginationItem-root {
  border-radius: 3px !important;
  min-width: 40px !important;
}

.MuiPagination-root ul.MuiPagination-ul .MuiButtonBase-root.MuiPaginationItem-root {
  border: 0.5px solid #dfdedd !important;
  border-radius: 0 !important;
  min-width: 30px !important;
  height: 38px !important;
}


.MuiBox-root.css-10gei56 {
  position: relative;
  margin-top: 10px;
  box-shadow: none;
}

.MuiBox-root.css-10gei56 .css-exd1zr {
  position: absolute;
  right: 0;
}

.MuiPaper-root.MuiPaper-elevation .MuiFormLabel-root.MuiInputLabel-root {
  margin-top: 6px;
}

.MuiPaper-root.MuiPaper-elevation .MuiInputBase-root.MuiInput-root.MuiInputBase-colorPrimary {
  min-width: 16px;
  border: 1px solid #DFDEDD;
  border-radius: 3px;
  padding: 3px 6px 0px 8px !important;
  margin-top: 4px;
  font-family: Lato !important;
}

#mrt-rows-per-page {
  background-color: transparent;
}

/*** Table Pagination End ***/


/*** Create Job/Edit/Pause and Form Section Start***/

.create-job-modal .MuiModal-backdrop {
  background-color: #fff !important;
  border-radius: 8px;
  top: 90px;
  width: 97.8%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12) !important;
  margin-left: 20px;
  margin-right: 20px;
}

.create-job-modal.MuiModal-root .MuiBox-root {

  top: 390px;
  width: 97%;
  /* border-radius: 8px;*/
  box-shadow: none !important;
}

.create-job-modal .MuiBox-root {
  max-height: 600px;
  padding: 210px 20px 20px 110px;
}

.custom-form-wrapper {
  padding: 0 10px;
}

.custom-form-wrapper .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border-color: #DFDEDD !important;
  border-width: 0.5px !important
}

.custom-form-wrapper .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #DFDEDD !important;
  border-width: 0.5px !important
}

.custom-form-wrapper .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #DFDEDD !important;
}

.custom-form-wrapper .req-wrap label::after {
  content: "*";
  color: #ff4444;
  font-size: 14px;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiSelect-root {
  font-family: Lato !important;
}

.MuiMenu-paper ul.MuiMenu-list li.MuiMenuItem-root {
  color: #717171;
  margin-bottom: 3px;
  padding: 4px 15px 2px 15px;
  font-family: Lato !important;
}

.MuiMenu-paper ul.MuiMenu-list li.MuiMenuItem-root:hover {
  background-color: #ededee !important;
  border-radius: 3px
}

.MuiButtonBase-root.MuiMenuItem-root.Mui-selected {
  background-color: #ededee !important;
  color: #0052ce !important;
  border-radius: 3px !important;
}

.create-job-modal .MuiTypography-root {
  max-height: none !important;
  overflow-y: auto !important;
}

.create-job-modal .MuiTypography-body1 {
  padding: 0 0 25px 20px;
  font-family: Lato !important;
  overflow-x: hidden;
}

.title-page-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.back-icon {
  line-height: 0 !important;
}

.back-icon:before {
  content: url("./fonts/back.svg") !important;
  cursor: pointer;
  line-height: 0;
}

.title-modal {
  margin-left: 14px;
  margin-top: 4px;
}

.create-job-modal.MuiModal-root .MuiTypography-h6 {
  font-size: 26px;
  color: #002d72;
  padding: 20px 30px 0;
  font-weight: 400;
  font-family: Lato !important;
}

.create-job-modal .MuiInputBase-formControl.MuiInputBase-multiline {
  font-family: Lato !important;
}

.css-uqq6zz-MuiFormLabel-root-MuiInputLabel-root {
  color: #434a51 !important;
  font-size: 16px !important;
  font-family: Lato !important;
  margin-right: 10px;
}

.create-job-modal .MuiInputLabel-formControl {
  font-size: 16px !important;
  font-weight: 400;
  color: #434a51 !important;
  font-family: Lato !important;
  transform: none;
}

.create-job-modal .MuiOutlinedInput-root,
.create-job-modal .MuiFormControl-root {
  width: 100%;
}

.create-job-modal .MuiOutlinedInput-root {
  border-radius: 3px;
  height: 38px;
}

.create-job-modal .MuiFormLabel-root.MuiInputLabel-root {
  color: #434a51 !important;
  margin-bottom: 4px;
}

.create-job-modal .MuiInputBase-root.MuiInput-root {
  font-family: Lato !important;
  color: #434a51 !important;
  font-size: 16px !important;
}

.create-job-modal .MuiInputBase-input.MuiInput-input {
  border: 1px solid #dfdedd !important;
  border-radius: 3px !important;
  padding: 7px 10px;
}

.create-job-modal .MuiFormLabel-root.MuiInputLabel-root {
  transform: none !important;
}

.create-job-modal .MuiInputBase-root.MuiInput-root:before,
.create-job-modal .MuiInputBase-root.MuiInput-root:after {
  border-bottom: none !important;
}

.create-job-modal label+.MuiInputBase-root.MuiInput-root {
  margin-top: 27px;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon:before {
  content: url("./fonts/chevron-down.svg");
}

.create-job-modal .MuiInputBase-root.MuiOutlinedInput-root {
  padding-right: 0;
}

.create-job-modal .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-multiline {
  height: 400px;
}

.create-job-modal .MuiButtonBase-root.MuiMenuItem-root.Mui-selected,
.create-job-modal .MuiButtonBase-root.MuiMenuItem-root:hover {
  background-color: #ededee !important;
  border-radius: 3px;
}

.rep-sql-wrap .MuiInputLabel-formControl {
  background-color: #fff;
}

.rep-sql-wrap textarea {
  height: 340px !important;
  padding-right: 10px;
}

.rep-sql-wrap .MuiOutlinedInput-notchedOutline {
  border-color: #DFDEDD !important;
  border-width: 0.5px !important;
}

.rep-sql-wrap .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #DFDEDD !important;
  border-width: 0.5px !important;
}



.modalFooter-create-job {
  padding: 0 25px;
  text-align: right;
  margin-top: 20px;
}

.MuiFormLabel-asterisk {
  font-size: 13px;
  color: #dc3545;
}


/***Create Job/Edit/Pause and Form Section  End***/


/*** Modal Start ***/
.modalWrapper {
  /* position: absolute; */
  /* top: 50%;
  left: 50%; */
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 4px;
  border-radius: 8px;
  /* background-color: #fff; */
}


.MuiModal-root .MuiButtonBase-root.MuiButton-root {
  min-height: 30px;
  min-width: 80px;
  box-shadow: none;
  padding: 4px 15px 2px;
  text-transform: capitalize;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
  font-family: Lato !important;
  border-radius: 3px;
}

.MuiModal-root .MuiButtonBase-root.MuiButton-root.MuiButton-containedPrimary {
  background-color: #0052ce;
  border: 1px solid #0052ce;
  color: #fff !important;
}

.MuiModal-root .MuiButtonBase-root.MuiButton-root.MuiButton-containedPrimary.Mui-disabled {
  background-color: #a0c4f8 !important;
  border: 1px solid #a0c4f8;
}

.MuiModal-root .MuiButtonBase-root.MuiButton-root.MuiButton-textPrimary {
  background-color: transparent !important;
  border: 1px solid #0052ce !important;
  margin-right: 20px !important;
  color: #0052ce !important;
}

.custom-modal .MuiBox-root {
  border-radius: 8px;
}

.custom-modal.custom-modal-sm .MuiBox-root {
  max-width: 644px;
  min-width: 644px;
  box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, .25);
}

.custom-modal .MuiTypography-h6 {
  font-size: 24px;
  color: #002d72;
  padding: 10px 20px;
  border-bottom: .5px solid #dfdedd;
  font-family: Lato !important;
}

.custom-modal .MuiTypography-body1 {
  margin-top: 0;
}

.custom-modal .modal-content-body {
  padding: 0 20px;
}

.custom-modal .modal-content-body.sm-content {
  color: #434a51;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  padding: 50px 0 80px;
}

.custom-modal .modal-footer {
  text-align: right;
  padding: 0 20px 25px;
}

.custom-modal #delete-btn {
  background-color: #f44 !important;
  border: 1px solid #f44 !important;
}

/*** Modal End ***/


/**Grid Scrollbar Start**/

.MuiTableContainer-root::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: white;
}

.MuiTableContainer-root::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: white;
  position: absolute;
}

.MuiTableContainer-root::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: lightgray;
  position: absolute;
}

.css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: white;
}

.css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: white;
  position: absolute;
}

.css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: lightgray;
  position: absolute;
}


.MuiPaper-elevation.MuiMenu-paper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  background-color: white;
}

.MuiPaper-elevation.MuiMenu-paper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: white;
  position: absolute;
}

.MuiPaper-elevation.MuiMenu-paper::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: lightgray;
  position: absolute;
}

/**Grid Scrollbar end**/

/**No Data CSS Start**/

.empty-job-wrap {
  padding: 100px 100px 50px 100px;
  border: none !important;
  position: relative;
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  min-width: 0;
  min-height: 300px;
  background: #fff !important;
  border-radius: 8px !important;
  margin-top: 15px;
  margin-right: 20px;
  margin-left: 20px;
}

.empty-job-wrap .p-col-3 {
  width: 390px;
}

.empty-job-wrap .p-col-2 {
  width: 124px;
}

.empty-job-wrap .empty-title {
  color: #002d72;
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 15px;
  margin-top: 75px;
}

.empty-job-wrap .empty-sub-title {
  color: #434a51;
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 15px;
  margin-top: 0;
}
/**No Data CSS end**/

.MuiCircularProgress-root {
  color: #0052ce !important;
  left: 50%;
  position: relative;
}

/**Tooltip CSS start**/

.tooltip-irm-save {
  position: relative;
  display: inline-block;
}

.tooltip-irm-save .tooltiptext-irm {
  visibility: hidden;
  width: 280px;
  background-color: #434a51;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -10px;
  right: 110%;
  margin-left: -11em;
  text-align: start;
  padding-left: 10px;
  font-size: 12px;
  font-weight: 400;
  padding: 12px;
}

.tooltip-irm-save:hover .tooltiptext-irm {
  visibility: visible;
}

.tooltip-irm-save .tooltiptext-irm::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;  
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #434a51;
}

.d-inline-block {
  display: inline-block;
}

/** Tooltip CSS End**/


/**p-grid*/

* {
  box-sizing: initial;
}

body .form-group {
  margin-bottom: 10px;
}

.p-grid>.p-col,
.p-grid>[class*=p-col] {
  box-sizing: border-box;
}

.p-grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
}

.p-col-1,
.p-col-2,
.p-col-3,
.p-col-4,
.p-col-5,
.p-col-6,
.p-col-7,
.p-col-8,
.p-col-9,
.p-col-10,
.p-col-11,
.p-col-12 {
  flex: 0 0 auto;
  padding: 0.5rem;
}

.p-col-1 {
  width: 8.3333%;
}

.p-col-2 {
  width: 16.6667%;
}

.p-col-3 {
  width: 25%;
}

.p-col-4 {
  width: 33.3333%;
}

.p-col-5 {
  width: 41.6667%;
}

.p-col-6 {
  width: 48%;
}

.p-col-7 {
  width: 58.3333%;
}

.p-col-8 {
  width: 66.6667%;
}

.p-col-9 {
  width: 75%;
}

.p-col-10 {
  width: 83.3333%;
}

.p-col-11 {
  width: 91.6667%;
}

.p-col-12 {
  width: 100%;
}